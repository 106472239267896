import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./components/nav";
import Cover from "./components/cover";
import { Profile } from "./components/team";
import WhoAll from "./components/whoAll";
import Footer from "./components/footer";
import What from "./components/what";
import Clients from "./components/clients";
import FormTemp from "./components/formTemp";
import Login from "./components/login";
import PracticeAreas from "./components/practiceAll";
import Connect from "./components/connect";
import { TeamMembers } from "./components/teamMembers";
import { AdvisoryMembers } from "./components/teamMembers";

import Contact from "./components/contact";
import Articles from "./components/articles";
import AllArticles from "./components/allArticles";
import AllVideos from "./components/allVideos";
import { StratProfile } from "./components/stratTeam";
import TeamDisplay from "./components/teamCar";
import PaForm from "./components/paForm";
import TeamForm from "./components/memberForm";
import AdvisoryForm from "./components/advisoryForm";
import ConnectionForm from "./components/connectionForm";
import ClientForm from "./components/clientForm";

import VideoForm from "./components/videoForm";
import Admin from "./components/adminPage";

function App() {
  // state to manage the dark mode
  const [toggleDarkMode, setToggleDarkMode] = useState(false);

  // function to toggle the dark mode as true or false
  const toggleDarkTheme = () => {
    setToggleDarkMode(!toggleDarkMode);
  };

  // applying the primary and secondary theme colors
  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? "dark" : "light",
      // handle the dark mode state on toggle
      primary: {
        main: "#90caf9",
      },

      secondary: {
        main: "#131052",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      {" "}
      <CssBaseline /> <NavBar />{" "}
      <Router>
        {" "}
        <div className="App">
          {" "}
          <Routes>
            <Route exact path="/admin" element={<Admin />} />
            {/* Admin forms */}
            <Route
              exact
              path="/connections/form"
              element={<ConnectionForm />}
            />
            <Route
              exact
              path="connection/video&podcasts/form"
              element={<FormTemp />}
            />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/client/form" element={<ClientForm />} />
            <Route exact path="/video/form" element={<VideoForm />} />
            <Route exact path="/advisory/form" element={<AdvisoryForm />} />
            <Route exact path="/practice-areas/form" element={<PaForm />} />
            <Route exact path="/team/form" element={<TeamForm />} />
            {/* Normal Pages */}
            <Route exact path="/" element={<Cover />} />{" "}
            <Route exact path="/team" element={<TeamDisplay />} />
            <Route
              exact
              path="/advisory-team"
              element={<StratProfile data={AdvisoryMembers} />}
            />
            <Route
              exact
              path="/who-we-are"
              element={<WhoAll data={AdvisoryMembers} />}
            />
            <Route exact path="/what-we-do" element={<What />} />
            <Route exact path="/clients" element={<Clients />} />
            <Route exact path="/contact-us" element={<Contact />} />
            <Route exact path="/e&k-connect" element={<Connect />} />
            <Route exact path="/practice-areas" element={<PracticeAreas />} />
            <Route path="/articles/:connectionTitle" element={<Articles />} />
            <Route
              exact
              path="/e&k-connect/all-videos"
              element={<AllVideos />}
            />
            <Route
              exact
              path="/e&k-connect/all-articles"
              element={<AllArticles />}
            />
          </Routes>{" "}
        </div>{" "}
      </Router>{" "}
      <Footer />
    </ThemeProvider>
  );
}

export default App;
