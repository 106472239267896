import React, { useState, useEffect } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import "./team.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import axios from "axios";

export const Profile = ({ data, onClickIndex }) => {
  const [index, setIndex] = useState(onClickIndex);
  const [contactPhoto, setContactPhoto] = useState("");

  useEffect(() => {});

  const slideLeft = () => {
    setIndex(index === 0 ? data.length - 1 : index - 1);
  };

  const slideRight = () => {
    setIndex(index === data.length - 1 ? 0 : index + 1);
  };

  return (
    <div className="container-fluid" id="teamCarousel">
      <div className="row">
        <div className="col-sm-1">
          <GrPrevious className="teamArrow" size={40} onClick={slideLeft} />
        </div>
        <div className="col-sm-10">
          {data.map((item, idx) => {
            return (
              <div id="teamMemberModal" className="container-fluid">
                <Card
                  className={index === idx ? "index" : "index index-hidden"}
                  id="teamCard"
                  key={idx}
                >
                  <Card.Body>
                    <div className="container-fluid" id="teamInfo">
                      {" "}
                      <h4 id="memberName">{item.name}</h4>
                      <p id="memberTitle">{item.title}</p>
                      <p id="memberTitle">{item.email}</p>
                      <div className="container" id="contactImageCard">
                        <Image
                          fluid
                          src={item.photo}
                          key={item.name}
                          style={{
                            maxHeight: "250px",
                            maxWidth: "200px",
                            margin: "auto",
                            marginBottom: "30px",
                            borderRadius: "50%",
                          }}
                          id="pAreaContact"
                          roundedCircle
                        />

                        <Image
                          fluid
                          src={lnGr}
                          style={{ margin: "auto" }}
                          alt="https://icons8.com/icon/447/linkedin"
                        />
                      </div>
                      <div id="teamCarBody">
                        <pre>
                          {" "}
                          <p id="memberBody">{item.body}</p>
                        </pre>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
        <div className="col-sm-1">
          {" "}
          <GrNext className="teamArrow" size={40} onClick={slideRight} />
        </div>
      </div>
    </div>
  );
};
