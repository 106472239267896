import React, { useState, useEffect } from "react";
import { Card, Col, Row, Image } from "react-bootstrap";
import "./allArticles.css";
import axios from "axios";
import energy from "../media/images/energy.png";
import desc from "../media/images/desc.png";

function AllArticles() {
  const [articles, setArticles] = useState([]);
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    getArticle();
  }, []);
  function getArticle() {
    axios
      .get("http://localhost/ekconsul_new_ek/connectionSearch.php")
      .then(function (response) {
        setArticles(response.data);

        /*     const articleFilter = connections.filter(
          (item) => item.type == "article"
        ); */
      });
  }
  return (
    <div className="container" id="allArticlesDiv">
      <Row>
        <h4
          style={{
            textTransform: "uppercase",
            letterSpacing: "2px",
            float: "left",
            marginBottom: "20px",
          }}
        >
          Articles
        </h4>
      </Row>

      <Row style={{ display: "flex", flexDirection: "row" }}>
        {articles.map((article, idx) => (
          <Col sm={4}>
            <a
              id="allArticleLink"
              href="/e&k-connect/articles"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              <Card key={idx} id="allArticlesCard" as="href">
                <Card.Img variant="top" id="allArticleImage" src={energy} />
                <Card.Body>
                  <Card.Title id="allArticlesTitle">{article.title}</Card.Title>
                  <Card.Subtitle id="allArticlesSub">
                    {article.author}
                  </Card.Subtitle>
                  <Card.Text id="allArticlesText">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>{" "}
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default AllArticles;
