import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "./authContext";
import "./login.css";
function Login() {
  const [user, setUser] = useState([]);

  const [formData, setFormData] = useState({
    userName: "",
    userPassword: "",

    // Add other form fields as needed
  });
  const resetForm = () => {
    setFormData({
      userName: "",
      userPassword: "",
      // Set other form fields to their initial state or empty values
    });
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Make an Axios POST request to the PHP backend
    axios
      .post(`http://localhost/ekconsul_new_ek/login.php`, formData)
      .then(function (response) {
        console.log(formData);
      });
  };

  return (
    <div className="container-fluid" id="loginDiv">
      {" "}
      <form onSubmit={handleSubmit} id="loginForm">
        <h4 style={{ marginTop: "0.5em" }}>Please Sign In </h4>

        <div className="input-group">
          <label>
            Username{" "}
            <input
              required
              type="text"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Password:
            <input
              required
              type="password"
              name="userPassword"
              value={formData.userPassword}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="container-fluid" id="loginButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
