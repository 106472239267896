import React, { useState, useEffect } from "react";
import "./connect.css";
import axios from "axios";
import "shikwasa/dist/style.css";
import energy from "../media/images/energy.png";
import pub from "../media/images/pub.jpg";
import east from "../media/images/east.jpg";
import { Player, Chapter } from "shikwasa";
import { Row, Card, Button, Col, Image } from "react-bootstrap";
import { NewsViewer } from "./newsView";

function Connect() {
  const [videoData, setVideoData] = useState([]);
  const [showDisplay, setShowDisplay] = useState(false);
  const [connections, setConnections] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [publications, setPublications] = useState([]);

  /* 
  const player = new Player({
    audio: {
      title: "Our first podcast!",
      artist: "E&K ",
      category: "",
      cover: "energy.png",
      src: "/",
      themeColor: "#1AA99F",
    },
  });
 */
  const handleChange = () => {
    setShowDisplay((showDisplay) => true);
  };
  useEffect(() => {
    getArticle();
    getVideos();
    getNews();
  }, []);
  function getArticle() {
    axios
      .get("http://localhost/ekconsul_new_ek/connectionSearch.php")
      .then(function (response) {
        setArticles(response.data);
      });
  }
  function getNews() {
    axios
      .get("http://localhost/ekconsul_new_ek/newsSearch.php")
      .then(function (response) {
        setNewsItems(response.data);
      });
  }
  function getVideos() {
    axios
      .get("http://localhost/ekconsul_new_ek/videos.php")
      .then(function (response) {
        setVideoData(response.data);
      });
  }
  function getPublications() {
    axios
      .get("http://localhost/ekconsul_new_ek/publication.php")
      .then(function (response) {
        setPublications(response.data);
      });
  }

  return (
    <div
      className="container-fluid"
      id="connectDiv"
      style={{
        backgroundImage: "url(" + east + ")",
      }}
    >
      <div className="container-fluid" id="connectInnerDiv">
        <h4
          style={{
            letterSpacing: "2px",
            textTransform: "uppercase",
            marginTop: "15px",
          }}
        >
          Connections
        </h4>{" "}
        <div
          className="container-fluid"
          id="connectRowHeading"
          style={{
            borderBottom: "1px solid #1AA99F",
          }}
        >
          <h4 id="connectionTitle">VIDEOS</h4>
          <Button
            size="lg"
            id="connectBtn"
            href="/e&k-connect/all-videos"
            variant="outline"
          >
            Watch All
          </Button>
        </div>
        <div className="container-fluid" id="connectRow">
          {videoData.map((video, idx) => (
            <Card
              key={idx}
              id="videoCard"
              style={{ margin: "30px", borderRadius: "20px", border: "none" }}
            >
              {" "}
              <Card.Body>
                {" "}
                <iframe
                  height="430"
                  src={video.link}
                  style={{ borderRadius: "20px" }}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />{" "}
                <Card.Text>
                  <h5 id="videoTitle">{video.title}</h5>
                  <h6 id="videoCat">{video.category}</h6>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>{" "}
        <div className="container-fluid" id="connectRowHeading">
          {" "}
          <h4 id="connectionTitle">ARTICLES</h4>{" "}
          <Button
            size="lg"
            id="connectBtn"
            href="/e&k-connect/all-articles"
            variant="outline"
          >
            Read All
          </Button>
        </div>
        <div className="container-fluid" id="articleRow">
          {articles.slice(0, 3).map((article, idx) => (
            <a
              href="/e&k-connect/articles"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              <Card key={idx} id="connectCard" as="href">
                <Card.Body>
                  <Card.Title id="contentTitle">{article.title}</Card.Title>

                  <Card.Text id="contentText">{article.author}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          ))}{" "}
        </div>{" "}
      </div>
      <div className="container" id="quoteRow">
        <div className="container-fluid" id="innerQuoteRow">
          {" "}
          <h3 className="quote">"kinolewacho hukata"</h3>
          <p className="quoteEng">What is sharpened, cuts</p>
          <p className="quoteLang">
            Kiswahili
            <br /> East African Proverb
          </p>
        </div>
      </div>
      <div className="container-fluid" id="connectInnerDiv">
        <div className="container-fluid" id="connectRowHeading">
          {" "}
          <h4 id="connectionTitle">PUBLICATIONS AND JOURNALS</h4>{" "}
          <Button
            size="lg"
            id="connectBtn"
            href="/e&k-connect/publications"
            variant="outline"
          >
            Read All
          </Button>
        </div>
        <div className="container-fluid" id="pubRow">
          {publications.map((publication, idx) => (
            <a
              href={publication.link}
              target="blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              <Card
                key={idx}
                id="videoCard"
                style={{ margin: "30px", borderRadius: "20px", border: "none" }}
              >
                {" "}
                <Card.Body>
                  {" "}
                  <Card.Text>
                    <h3 id="videoTitle">{publication.title}</h3>
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          ))}
        </div>
        <div className="container-fluid" id="connectRowHeading">
          <h4 id="connectionTitle">NEWS</h4>{" "}
          <Button size="lg" id="connectBtn" href="/" variant="outline">
            Read All
          </Button>
        </div>
        <div className="container-fluid" id="newsDiv">
          <NewsViewer data={newsItems} />
        </div>
        <div className="container-fluid" style={{ marginBottom: "30px" }}>
          {" "}
          <h4 id="connectionTitle">PODCASTS</h4>{" "}
          <div className="container-fluid" id="podcastDiv">
            <h2 style={{ paddingTop: "5.5em" }}>COMING SOON</h2>
            <p> We are working on a new to connect with you more. </p>
            <Button
              size="lg"
              style={{ textTransform: "capitalize", color: "black" }}
              id="connectBtn"
              href="/who-we-are"
              variant="outline"
            >
              Notify Me
            </Button>
          </div>
        </div>
        {/*  {showDisplay && (
        <div className="container" id="podPlayer">
          Podcast Player
        </div>
      )}
      <div className="container-fluid" id="podRow">
        {Array.from({ length: 3 }).map((_, idx) => (
          <Button id="podButton" variant="outline-light" onClick={handleChange}>
            {" "}
            <Card className="bg-dark text-white" id="connectCard" style={{}}>
              <Card.Img variant="top" id="podThumbnail" src={energy} />
              <Card.ImgOverlay>
                <Card.Title id="podTitle">Podcast title</Card.Title>
                <Card.Text id="podDate">Date</Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Button>
        ))}
      </div>{" "} */}
      </div>
    </div>
  );
}

export default Connect;
