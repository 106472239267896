import React, { useState, useEffect } from "react";
import "./cover.css";
import { Card, Col, Row, Image, Button } from "react-bootstrap";
import "./practice.css";
import axios from "axios";
import energy from "../media/images/energy.png";
import health from "../media/images/health.jpg";
import inn from "../media/images/inn.jpg";
import fin from "../media/images/fin.jpg";
import cap from "../media/icons/cap.png";
import city from "../media/icons/city.png";
import client from "../media/icons/client.png";
import people from "../media/icons/people.png";
import sdg from "../media/images/sdg.jpg";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
function PracticeAreas() {
  const [count, setCount] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [practiceTitle, setPracticeTitle] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  let navigate = useNavigate();

  const handleClick = async (e) => {
    try {
      // Make an Axios request to your PHP backend with the search query
      const response = await axios.post(
        "http://localhost/ekconsul_new_ek/practice/",
        {
          query: searchQuery,
        }
      );

      // Handle the response data as needed (e.g., update state or navigate to another page)
      console.log(response.data);
      setSearchQuery(e.target.name);
      // Redirect to a different page (replace 'your-target-page' with the actual page)
      navigate("what-we-do");
    } catch (error) {
      console.error("Error during search:", error);
    }
  };
  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container-fluid" id="prDiv">
      {" "}
      <h4
        style={{
          padding: "20px",
        }}
      >
        PRACTICE AREAS
      </h4>{" "}
      <div className="container" id="contentHub">
        <div className="container-fluid " id="fieldSummary">
          {" "}
          <Row id="plxCoverLeft">
            <Col style={{ margin: "auto" }}>
              {" "}
              <Card id="plxCard">
                <Card.Body>
                  <Card.Title id="plxCardTitle">
                    <h1>HEALTH</h1>{" "}
                  </Card.Title>

                  <Card.Text id="plxCardText">
                    <p id="prFact"></p>
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Developing high impact solutions across the health
                      ecosystem
                    </p>
                    <Button
                      size="lg"
                      id="practiceCoverBtn"
                      onClick={handleClick}
                      variant="outline"
                      name="health"
                    >
                      Discover More
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>{" "}
            {windowWidth >= 768 ? (
              <Col style={{ margin: "auto" }}>
                <div className="container-fluid">
                  {" "}
                  <Image
                    src={health}
                    fluid
                    style={{ margin: "20px", height: "300px" }}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <Row id="plxCoverRight">
            {" "}
            {windowWidth >= 768 ? (
              <Col style={{ margin: "auto" }}>
                <div className="container-fluid">
                  {" "}
                  <Image
                    src={fin}
                    fluid
                    style={{ margin: "20px", height: "300px" }}
                  />
                </div>
              </Col>
            ) : null}
            <Col style={{ margin: "auto" }}>
              {" "}
              <Card
                id="plxCard"
                name="finance"
                as="button"
                onClick={handleClick}
              >
                <Card.Body>
                  <Card.Title id="plxCardTitle">
                    <h1>FINANCE</h1>{" "}
                  </Card.Title>
                  <Card.Text id="plxCardText">
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Advising a broad spectrum of investors
                    </p>
                    <Button
                      size="lg"
                      id="practiceCoverBtn"
                      onClick={handleClick}
                      variant="outline"
                      name="finance"
                    >
                      Discover More
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>{" "}
          <Row id="plxCoverLeft">
            <Col style={{ margin: "auto" }}>
              {" "}
              <Card
                id="plxCard"
                name="innovation"
                as="button"
                onClick={handleClick}
              >
                <Card.Body>
                  <Card.Title id="plxCardTitle">
                    <h1>INNOVATION</h1>{" "}
                  </Card.Title>

                  <Card.Text id="plxCardText">
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Fostering potential for new solutions
                    </p>
                    <Button
                      size="lg"
                      id="practiceCoverBtn"
                      onClick={handleClick}
                      variant="outline"
                      name="innovation"
                    >
                      Discover More
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>{" "}
            {windowWidth >= 768 ? (
              <Col style={{ margin: "auto" }}>
                <div className="container-fluid">
                  {" "}
                  <Image
                    src={inn}
                    fluid
                    style={{ margin: "20px", height: "300px" }}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <Row id="plxCoverRight">
            {" "}
            {windowWidth >= 768 ? (
              <Col style={{ margin: "auto" }}>
                <div className="container-fluid">
                  {" "}
                  <Image
                    src={sdg}
                    fluid
                    style={{ margin: "20px", height: "300px" }}
                  />
                </div>
              </Col>
            ) : null}
            <Col style={{ margin: "auto" }}>
              {" "}
              <Card
                name="energy"
                as="button"
                onClick={handleClick}
                id="plxCard"
              >
                <Card.Body>
                  <Card.Title id="plxCardTitle">
                    <h1>ENERGY</h1>{" "}
                  </Card.Title>
                  <Card.Text id="plxCardText">
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Championing solutions to the climate change threat
                    </p>
                    <Button
                      size="lg"
                      id="practiceCoverBtn"
                      onClick={handleClick}
                      variant="outline"
                      name="energy"
                    >
                      Discover more
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>{" "}
        </div>
      </div>
      <div className="container" id="prInfo">
        <Row>
          <Col style={{ justifyContent: "center" }}>
            {" "}
            <Image
              style={{ height: "75px" }}
              src={city}
              alt="https://icons8.com/"
            />
            <h3 id="practiceFactsH">
              <CountUp end={30} duration={8} />+ Countries
            </h3>{" "}
            <p id="practiceAreaP">Global Experience, Local Knowledge</p>
          </Col>
          <Col>
            {" "}
            <Image
              style={{ height: "75px" }}
              src={people}
              alt="https://icons8.com/"
            />
            <h3 id="practiceFactsH">
              <CountUp end={200} duration={8} />+ Million People
            </h3>{" "}
            <p id="practiceAreaP">Impacted through our Engagements</p>
          </Col>
          <Col>
            {" "}
            <Image
              style={{ height: "75px" }}
              src={client}
              alt="https://icons8.com/"
            />
            <h3 id="practiceFactsH">
              <CountUp end={100} duration={8} />+ Clients Served
            </h3>{" "}
            <p id="practiceAreaP">Addressing Global Challenges</p>
          </Col>{" "}
          <Col>
            {" "}
            <Image
              style={{ height: "75px" }}
              src={cap}
              alt="https://icons8.com/"
            />
            <h3 id="practiceFactsH">
              $ <CountUp end={20} duration={8} />+ Billion Transactions Advised
            </h3>{" "}
            <p id="practiceAreaP">Deploying Capital for Impact</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PracticeAreas;
