import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Image,
  Card,
  CardGroup,
  CardFooter,
  Modal,
} from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import axios from "axios";
import { Profile } from "./team";
import west from "../media/images/west.jpg";
import "./whoAll.css";
import { AdvisoryMembers, TeamMembers } from "./teamMembers";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { GrNext, GrPrevious } from "react-icons/gr";

function WhoAll() {
  const [show, setShow] = useState(false);
  const [team, setTeam] = useState([]);
  const [advisoryTeam, setAdvisoryTeam] = useState([]);
  const [advisory, setAdvisory] = useState([]);
  const [member, setMember] = useState([]);
  const [clickIndex, setClickIndex] = useState([]);

  useEffect(() => {
    getTeam();
    getAdvisory();
  }, []);

  /**
   * Gets the team from Ekconsul and sets the member accordingly. This is called on load
   */
  function getTeam() {
    axios
      .get("http://localhost/ekconsul_new_ek/team/getTeam.php")
      .then(function (response) {
        setMember(response.data);
      });
  }
  /**
   * Get advisory data from EK and store it in the database for
   */
  function getAdvisory() {
    axios
      .get("http://localhost/ekconsul_new_ek/advisory/")
      .then(function (response) {
        setAdvisoryTeam(response.data);
      });
  }

  const searchTeam = (name) => {
    axios
      .get(`http://localhost/ekconsul_new_ek/team/teamSearch.php?name=${name}`)
      .then(function (response) {
        setTeam(response.data);
      });
  };

  const searchAdvisory = (name) => {
    axios
      .get(
        `http://localhost/ekconsul_new_ek/team/advisorySearch.php?name=${name}`
      )

      .then(function (response) {
        setAdvisory(response.data);
      });
  };

  const [advisoryShow, setAdvisoryShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleAdivsoryShow = () => setAdvisoryShow(true);
  const handleAdvisoryClose = () => setAdvisoryShow(false);
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === advisoryTeam.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? advisoryTeam.length - 1 : prevIndex - 1
    );
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const ModalSettings = {
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,

    nextArrow: (
      <div>
        {" "}
        <div className="next-slick-arrow" id="carControls">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            id="controlSvg"
          >
            {" "}
            <style>@import url(whoAll.css); </style>{" "}
            <path
              stroke="#1AA99F"
              d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z"
            />{" "}
          </svg>{" "}
        </div>{" "}
      </div>
    ),

    prevArrow: (
      <div>
        {" "}
        <div className="next-slick-arrow rotate-180" id="carControls">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="#1AA99F"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            {" "}
            <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" />{" "}
          </svg>{" "}
        </div>{" "}
      </div>
    ),
  };

  return (
    <div
      className="container-fluid"
      id="whoAllDiv"
      style={{
        backgroundImage: "url(" + west + ")",
      }}
    >
      {" "}
      <div className="container-fluid" id="whoAllContentDiv">
        {" "}
        <div className="container">
          {" "}
          <h4
            style={{
              marginTop: "15px",
            }}
          >
            WHO WE ARE
          </h4>{" "}
          <div className="whoRow">
            {" "}
            <p className="whoP">
              {" "}
              At E&amp; K, collaboration meets ingenuity in navigating the
              intricate tapestry of challenges that face our world today. With
              the needs of our clients, partners and communities at the heart of
              our work; and our roots deeply embedded in advising health sector
              leaders, our dynamic, multi-disciplinary team of seasoned
              strategists is fuelled by a passion for impact. Together, we
              leverage our global expertise, deep in-country experience and
              communities, to address some of the most intractable challenges of
              our time and work towards a world where shared prosperity is a
              reality; crafting bespoke solutions that not only address
              society’s evolving complexities, but also spark the transformative
              flames of collective action. Our expertise transcends borders
              having worked with over 100 clients in 20+ countries across the
              public, private, and development sectors in Africa, Europe, and
              beyond. Through extensive research, market studies, and strategic
              shaping, we don&#39; t just advise—we co-create pathways for
              progress and actively co-invest alongside our partners to realize
              our collective vision for the future.{" "}
            </p>{" "}
          </div>{" "}
          <h4
            style={{
              textTransform: "uppercase",
            }}
          >
            Our North Star
          </h4>{" "}
          <div className="whoRow">
            {" "}
            <p className="northStarP">
              {" "}
              <ul id="northStarUl">
                {" "}
                <li id="northStarLi">
                  {" "}
                  We believe and work towards the synergy between enterprise and
                  purpose where social purpose drives sustainable growth and
                  deeper impact.{" "}
                </li>{" "}
                <li id="northStarLi">
                  {" "}
                  By nurturing the continent’s next leaders, we champion a
                  globally competitive and socially responsible Africa that
                  creates solutions to the world’s challenges{" "}
                </li>{" "}
                <li id="northStarLi">
                  {" "}
                  We boldly approach pressing global challenges with curiosity.{" "}
                </li>{" "}
                <li id="northStarLi">
                  {" "}
                  We cultivate robust partnerships with our clients and
                  collaborate to co-create lasting impact.{" "}
                </li>{" "}
              </ul>{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div className="container" id="quoteRow">
        {" "}
        <div className="container-fluid" id="innerQuoteRow">
          {" "}
          <h3 className="quote">"Otu onye tuo izu, o gbue ochu"</h3>{" "}
          <p className="quoteEng">
            {" "}
            Knowledge is never complete; Two heads are better than one{" "}
          </p>{" "}
          <p className="quoteLang">
            {" "}
            Igbo,
            <br /> West African Proverb{" "}
          </p>{" "}
        </div>{" "}
      </div>{" "}
      <div className="container-fluid" id="whoAllContentDiv">
        {" "}
        <h4
          style={{
            paddingTop: "20px",
          }}
        >
          TEAM
        </h4>{" "}
        <div className="container" id="teamMemberDiv">
          {" "}
          <h3
            style={{
              paddingTop: "1em",
              paddingBottom: "1em",
              fontFamily: "Playfair Display",
              color: "black",
              fontSize: "20px",
              letterSpacing: "3px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            Strategic Advisory Board Members{" "}
          </h3>{" "}
          {/* This is for use with a query like " select * from Teams where Id = 1 */}
          <Row xs={1} md={3} className="g-4" id="teamRow">
            {" "}
            {AdvisoryMembers.map((data, idx) => {
              return (
                <Col>
                  {" "}
                  <a className="teamLink">
                    {" "}
                    <Card
                      key={idx}
                      onClick={() => {
                        searchAdvisory(data.Name);
                        setClickIndex(idx);
                      }}
                      as="a"
                      className="teamLink"
                      id="whoTeamCard"
                    >
                      {" "}
                      <Card.Title>
                        {" "}
                        <Image
                          id="teamPhoto"
                          onClick={handleAdivsoryShow}
                          src={data.photo}
                          roundedCircle
                          fluid
                        />{" "}
                      </Card.Title>{" "}
                      <Card.Body>
                        {" "}
                        <Card.Text>
                          {" "}
                          <h5> {data.Name}</h5>{" "}
                        </Card.Text>{" "}
                      </Card.Body>{" "}
                    </Card>{" "}
                  </a>{" "}
                </Col>
              );
            })}
          </Row>{" "}
          <h3
            style={{
              paddingBottom: "1em",
              fontFamily: "Playfair Display",
              color: "black",
              fontSize: "20px",
              letterSpacing: "3px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            Team Members{" "}
          </h3>{" "}
          <Row xs={1} md={3} className="g-4" id="teamRow">
            {" "}
            {TeamMembers.map((data, teamIdx) => (
              <Col>
                {" "}
                <a className="teamLink">
                  {" "}
                  <Card
                    key={teamIdx}
                    onClick={() => {
                      searchTeam(data.Name);
                      setClickIndex(teamIdx);
                    }}
                    id="whoTeamCard"
                  >
                    {" "}
                    <Card.Title>
                      {" "}
                      <Image
                        id="teamPhoto"
                        src={data.photo}
                        onClick={handleShow}
                        roundedCircle
                        fluid
                      />{" "}
                    </Card.Title>{" "}
                    <Card.Body>
                      {" "}
                      <Card.Text>
                        {" "}
                        <h5> {data.Name}</h5> <p> {data.Title}</p>{" "}
                      </Card.Text>{" "}
                    </Card.Body>{" "}
                  </Card>{" "}
                </a>{" "}
              </Col>
            ))}
          </Row>{" "}
        </div>{" "}
      </div>{" "}
      <Modal
        show={advisoryShow}
        size="xl"
        id="teamModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleAdvisoryClose}
      >
        {" "}
        <Modal.Body>
          <Profile data={advisoryTeam} onClickIndex={clickIndex} />
        </Modal.Body>{" "}
      </Modal>{" "}
      <Modal
        show={show}
        size="xl"
        id="teamModal"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        {" "}
        <Modal.Body>
          <Profile data={member} onClickIndex={clickIndex} />
        </Modal.Body>{" "}
      </Modal>{" "}
    </div>
  );
}

export default WhoAll;
