import React, { useState } from "react";
import { Col, Row, Card, Modal, Button, Image } from "react-bootstrap";
import apply from "../media/images/apply.jpg";
import south from "../media/images/south.jpg";
import "./contact.css";
function Contact() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const newsSub = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    Array.from(document.querySelector("input")).forEach(
      (event) => (event.target = "")
    );
  };

  return (
    <div
      className="container-fluid"
      id="contactDiv"
      style={{
        backgroundImage: "url(" + south + ")",
      }}
    >
      <div className="container-fluid" id="contactInnerDiv">
        <h4 style={{ letterSpacing: "2px", marginTop: "15px" }}>CONTACT</h4>
        <Row style={{ marginLeft: "30px", marginTop: "3em" }}>
          <Col sm={4}>
            <p id="contactText">
              {" "}
              Suite A33
              <br />
              Upperhill Garden Apartments
              <br /> Third Ngong Avenue
              <br /> P.O.Box 61435-00200 Upper-Hill Area
              <br /> Nairobi,
              <br /> Kenya
            </p>
            <p id="contactText">Subscribe to our newsletter</p>

            <div
              className="container-fluid"
              style={{ float: "left", padding: "0" }}
            >
              {" "}
              <form className="subForm" onSubmit={newsSub}>
                {" "}
                <div className="form-group">
                  {" "}
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email address"
                    name="subEmail"
                  />{" "}
                  <button className="btn" id="subBtn" onClick={handleShow}>
                    Subscribe
                  </button>
                </div>
                <div className="form-group">
                  {" "}
                  <label>
                    <p id="contactText">
                      {" "}
                      Want to start a new project with us? Let’s Start!
                    </p>
                  </label>
                  <button className="btn" id="startBtn" onClick={handleShow}>
                    Start Now
                  </button>
                </div>
                <Modal show={show} centered onHide={handleClose}>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>Thank you for subscibing!</Modal.Body>
                  <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button> */}
                  </Modal.Footer>
                </Modal>{" "}
              </form>
            </div>
          </Col>

          <Col>
            <iframe
              id="locationMap"
              style={{ margin: "auto", width: "90%" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8005193850745!2d36.80732107496569!3d-1.2942009986935046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10c24782632f%3A0xda4b632b3389ba81!2sUpperhill%20Gardens%20Apartments%2C%20Third%20Ngong%20Ave%2C%20Nairobi%2C%20Kenya!5e0!3m2!1sen!2suk!4v1702508271857!5m2!1sen!2suk"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </Col>
        </Row>
        <div
          className="container-fluid"
          style={{ display: "block", padding: "20px" }}
        ></div>
      </div>
      <div className="container" id="quoteRow">
        <div
          className="container-fluid"
          style={{ backgroundColor: "#041f31", padding: "15px" }}
        >
          {" "}
          <h3 className="quote">Izandla ziyagezana</h3>
          <p className="quoteEng">Hands wash each other</p>
          <p className="quoteLang">
            IsiZulu,
            <br /> South African Proverb
          </p>
        </div>
      </div>

      <div className="container-fluid" id="contactInnerDiv">
        <h4 style={{ letterSpacing: "2px" }}>CAREERS</h4>
        <Row id="careersCoverRow">
          <Col style={{ margin: "auto" }}>
            {" "}
            <Card id="careersCard" as="a" href="/e&k-connect">
              <Card.Body>
                <Card.Title id="careersCardTitle">
                  Take your next steps with E&K consulting{" "}
                </Card.Title>

                <Card.Text id="careersCardText">
                  Lorem ipsum dolor sit amet, coctetur adipiscing fermentum
                  amet, phasellus sem nisl to massa. Enim, eget nisi quis risus
                  malesua nulla vitaecursus vel tellus magnis accumsan morbi.
                  <br />
                </Card.Text>
                <Button size="lg" id="contactBtn" href="/" variant="outline">
                  Apply Now
                </Button>
              </Card.Body>
            </Card>
          </Col>{" "}
          <Col>
            <div className="container-fluid d-none d-md-flex">
              {" "}
              <Image
                src={apply}
                fluid
                style={{ margin: "20px", height: "300px" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Contact;
