import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import "./article.css";
import axios from "axios";
import logo from "../media/logo/logo.png";
import { useParams } from "react-router-dom";
import desc from "../media/images/desc.png";
function Articles() {
  const [data, setData] = useState([]);
  const { connectionTitle } = useParams();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    // We use axios to send a GET request to the php file to fetch the data
    axios
      .get(
        "http://localhost/ekconsul_new_ek/readArticle.php/${connectionTitle}"
      )
      .then((response) => {
        // We handle the response from the php file

        // We update the result state variable with the response data
        setData(response.data);
        console.log(data);
      })
      .catch((error) => {
        // We handle the error from the php file
        console.error(error);
      });
  }, [connectionTitle]);
  // Access the item id from the URL params

  return (
    <div className="container" id="articleDiv">
      <Row style={{ justifyContent: "space-evenly", alignItems: "flex-start" }}>
        <Col sm={7}>
          {data && (
            <div>
              <h2 id="articleTitle">{data.title}</h2>
              <h6 id="articleDate" style={{ marginBottom: "2em" }}>
                {data.author}
              </h6>{" "}
              <p id="articleText" style={{ wordWrap: "break-word" }}>
                {data.body}
              </p>
            </div>
          )}
          <div className="container" id="currentArticleDiv">
            {data.map((article, idx) => (
              <div className={index === idx ? "index" : "index index-hidden"}>
                <h2 id="articleTitle">{article.title}</h2>
                <h6 id="articleDate" style={{ marginBottom: "2em" }}>
                  {article.author}
                </h6>{" "}
                <p id="articleText" style={{ wordWrap: "break-word" }}>
                  {article.body}
                </p>
              </div>
            ))}
          </div>
        </Col>
        <Col
          sm={4}
          style={{ margin: "10px", marginTop: "3em", float: "right" }}
        >
          <h6 style={{ color: "inherit" }}>More Articles</h6>

          {data.slice(1, 4).map((otherArticles, idx) => (
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={() => {
                setIndex(idx);
              }}
            >
              {" "}
              <Card id="articleCard">
                <Card.Body>
                  <Image id="articleImg" fluid />

                  <Card.Title id="articleCardTitle">
                    {otherArticles.title}
                  </Card.Title>
                  <Card.Subtitle id="articleSub">
                    {otherArticles.author}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </a>
          ))}
        </Col>
      </Row>
    </div>
  );
}

export default Articles;
