import React, { useState, useEffect } from "react";
import axios from "axios";
import "./formTemp.css";
import logoNav from "../media/logo/logoNav.png";
import { Image, Table } from "react-bootstrap";
function ClientForm() {
  const [client, setClient] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    photo: "",

    // Add other form fields as needed
  });
  const resetForm = () => {
    setFormData({
      name: "",
      category: "",
      photo: "",
      // Set other form fields to their initial state or empty values
    });
  };
  useEffect(() => {
    getClient();
  }, []);
  function getClient() {
    axios
      .get("http://localhost/ekconsul_new_ek/clients.php")
      .then(function (response) {
        setClient(response.data);
      });
  }
  const deleteProject = (name) => {
    axios
      .delete(`http://localhost/ekconsul_new_ek/clientDel.php?name=${name}`)
      .then(function (response) {
        getClient();
      });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Make an Axios POST request to the PHP backend
    axios
      .post("http://localhost/ekconsul_new_ek/clients.php", formData)
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
    alert("Submission Successful");
    resetForm();
    getClient();
  };
  return (
    <div className="container-fluid" id="formDiv">
      {" "}
      <form id="detailForm">
        <h4 style={{ marginTop: "0.5em" }}>New Client</h4>
        <p style={{ color: "black" }}>This form will add a new client.</p>
        <div className="input-group">
          <label>
            Client Name{" "}
            <input
              required
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Client Category
            <select
              onChange={handleChange}
              required
              name="category"
              value={formData.category}
              style={{ marginLeft: "20px" }}
            >
              <option value="" disabled selected>
                Select the category
              </option>
              <option value="DFI’s & Multilaterals">
                DFI’s & Multilaterals
              </option>
              <option value="Developing & Implementing partners">
                Developing & Implementing partners
              </option>
              <option value="Foundations, Academia and Research Institutes">
                Foundations, Academia and Research Institutes
              </option>
              <option value="Private sector client">
                Private sector client
              </option>
              <option value="Venture capitalists and impact investors">
                Venture capitalists and impact investors
              </option>
              <option value="Governments">Governments</option>
            </select>
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Client Logo{" "}
            <input required type="file" id="clientLogo" name="clientLogo" />
          </label>{" "}
        </div>
        <div className="container" id="formButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div>
        <h1 style={{ padding: "15px" }}>Client List</h1>
        <Table responsive bordered hover id="dsiplayTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category </th>

              <th>Photo</th>
            </tr>
          </thead>
          <tbody>
            {client.map((data, key) => (
              <tr key={key}>
                <td style={{ textTransform: "capitalize" }}>{data.name}</td>
                <td>{data.category}</td>
                <td>{data.image}</td>
                <td>
                  {" "}
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProject(data.name)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ClientForm;
