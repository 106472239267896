import React, { useState, useEffect } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import "./connect.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import axios from "axios";

export const NewsViewer = ({ data }) => {
  const [index, setIndex] = useState(0);
  return (
    <div className="container-fluid" id="newsViewerDiv">
      {" "}
      <Row>
        <Col sm={7}>
          {data.map((news, idx) => {
            return (
              <div
                key={idx}
                className={index === idx ? "index" : "index index-hidden"}
                onClick={() => {
                  setIndex(0);
                }}
                id="currentNewsDiv"
              >
                {" "}
                <h2 id="newsTitle">{news.title}</h2>
                <h6
                  id="newsDate"
                  style={{ marginBottom: "2em", textTransform: "capitalize" }}
                >
                  {news.area}
                </h6>
                <p id="newsText">{news.body}</p>{" "}
              </div>
            );
          })}
        </Col>

        <Col sm={4} style={{ margin: "10px" }}>
          {data.slice(1, 4).map((allnews, idx) => (
            <a
              key={idx}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              onClick={() => {
                setIndex(idx + 1);
              }}
            >
              {" "}
              <Card id="articleCard" key={idx}>
                <Card.Body>
                  <Card.Title id="articleCardTitle">{allnews.title}</Card.Title>
                </Card.Body>
              </Card>
            </a>
          ))}
        </Col>
      </Row>
    </div>
  );
};
