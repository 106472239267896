import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image, Table } from "react-bootstrap";

function VideoForm() {
  const [videoData, setVideoData] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    desc: "",
    category: "",
  });
  const resetForm = () => {
    setFormData({ title: "", link: "", desc: "", category: "" });
  };
  useEffect(() => {
    getVideos();
  }, []);
  function getVideos() {
    axios
      .get("http://localhost/ekconsul_new_ek/videos.php")
      .then(function (response) {
        setVideoData(response.data);
      });
  }

  // Delete member based on the unique identifier (link)
  const deleteVideo = (link) => {
    axios
      .delete(`http://localhost/ekconsul_new_ek/videosDel.php?link=${link}`)
      .then(function (response) {
        getVideos();
      });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Make an Axios POST request to the PHP backend
    axios
      .post("http://localhost/ekconsul_new_ek/videos.php", formData)
      // Handle the response from the PHP backend
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
    alert("Video added");
    console.log(formData);
    getVideos();
    resetForm();
  };

  return (
    <div className="container-fluid" id="formDiv">
      {" "}
      <form onSubmit={handleSubmit} id="detailForm">
        <h4 style={{ marginTop: "0.5em" }}>New Video</h4>
        <p style={{ color: "black" }}>This form will add a new video.</p>
        <div className="input-group">
          <label>
            Title{" "}
            <input
              required
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Link
            <input
              required
              type="text"
              name="link"
              value={formData.link}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Category
            <select
              onChange={handleChange}
              name="category"
              required
              value={formData.category}
              style={{ marginLeft: "20px" }}
            >
              <option value="" disabled selected>
                Select the category
              </option>
              <option value="health">Health</option>
              <option value="innovation">Innovation</option>
              <option value="energy">Energy</option>
              <option value="finance">Finance</option>
            </select>
          </label>{" "}
        </div>
        <div className="input-group">
          <label>Desc</label>{" "}
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            style={{
              marginLeft: "10px",
              width: "80%",
              height: "auto",
              overflow: "auto",
            }}
            rows="8"
          />
        </div>
        <div className="container" id="formButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="container-fluid">
        <h1 style={{ padding: "15px" }}>List videos</h1>
        <Table responsive bordered hover id="dsiplayTable">
          <thead>
            <tr>
              <th>Title</th>
              <th>Link</th>
              <th>Description</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {videoData.map((data, key) => (
              <tr key={key}>
                <td>{data.title}</td>
                <td>{data.link}</td>
                <td>{data.videoDesc}</td>
                <td>{data.category}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteVideo(data.link)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default VideoForm;
