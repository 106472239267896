import React, { useState, useEffect } from "react";
import axios from "axios";
import "./formTemp.css";
import logoNav from "../media/logo/logoNav.png";
import { Image, Table } from "react-bootstrap";
function AdvisoryForm() {
  const [member, setMember] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    summary: "",
    email: "",
    photo: "",
    // Add other form fields as needed
  });
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      summary: "",
      photo: "",
      // Set other form fields to their initial state or empty values
    });
  };
  useEffect(() => {
    getAdvisory();
  }, []);
  function getAdvisory() {
    axios
      .get("http://localhost/ekconsul_new_ek/advisory/")
      .then(function (response) {
        setMember(response.data);
      });
  }
  const deleteProject = (title) => {
    axios
      .delete(
        `http://localhost/ekconsul_new_ek/practice/del.php?title=${title}`
      )
      .then(function (response) {
        console.log(response.data);
      });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Make an Axios POST request to the PHP backend
    axios
      .post("http://localhost/ekconsul_new_ek/advisory/", formData)
      .then((response) => {
        console.log(response.data); // Handle the response from the PHP backend
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };
  return (
    <div className="container-fluid" id="formDiv">
      {" "}
      <form onSubmit={handleSubmit} id="detailForm">
        <span>
          <Image src={logoNav} style={{ height: "auto" }} fluid />
        </span>{" "}
        <h4 style={{ marginTop: "0.5em" }}>New Advisory Team Member</h4>
        <p style={{ color: "black" }}>
          This form will add a new member to the team.
        </p>
        <div className="input-group">
          <label>
            Name{" "}
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Email address
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Profile Photo{" "}
            <input
              type="file"
              id="myfile"
              name="photo"
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>Summary</label>{" "}
          <textarea
            name="summary"
            value={formData.summary}
            onChange={handleChange}
            style={{
              marginLeft: "10px",
              width: "80%",
              height: "auto",
              overflow: "auto",
            }}
            rows="8"
          />
        </div>
        <div className="container" id="formButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="container-fluid">
        <h1 style={{ padding: "15px" }}>List Advisory Members</h1>
        <Table responsive bordered hover id="dsiplayTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Bio</th>
              <th>Email</th>
              <th>Photo</th>
            </tr>
          </thead>
          <tbody>
            {member.reverse().map((data, key) => (
              <tr key={key}>
                <td style={{ textTransform: "capitalize" }}>{data.name}</td>
                <td>{data.body}</td>
                <td>{data.email}</td>
                <td>{data.photo}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProject(data.title)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default AdvisoryForm;
