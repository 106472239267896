import React, { useEffect, useState } from "react";
import axios from "axios";
import { TeamMembers } from "./teamMembers";
import "./practiceAll.css";
import {
  Card,
  Col,
  Row,
  Modal,
  Image,
  ButtonGroup,
  Carousel,
  ToggleButton,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import lnGr from "../media/icons/lnGr.png";
import { ArticleViewer } from "./articleViewer";

export default function PracticeAreas() {
  const [team, setTeam] = useState([]);
  const areas = [
    { name: "health" },
    { name: "energy" },
    { name: "finance" },
    { name: "innovation" },
  ];
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [practiceTitle, setPracticeTitle] = useState("health");
  const [filteredData, setFilteredData] = useState([]);
  const [sectorType, setSectorType] = useState([]);
  const [explore, setExplore] = useState([]);
  const [contactPhoto, setContactPhoto] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [contactData, setContactData] = useState([]);
  let imagePath;
  const filterData = (practiceTitle) => {
    const filteredData = sectorType.filter(
      (item) => item.category === practiceTitle
    );

    setFilteredData(filteredData);
  };
  const searchTeam = (name) => {
    axios
      .get(`http://localhost/ekconsul_new_ek/team/teamSearch.php?name=${name}`)
      .then(function (response) {
        setTeam(response.data);
        setShow(true);
        const photoFilter = (name) => {
          const filteredData = TeamMembers.filter((item) => item.Name == name);
          const photoField = filteredData.map((item) => item.photo);
          setContactPhoto(photoField);
        };
        photoFilter(uniqueContact);
      });
  };
  useEffect(() => {
    getPractice();
    getConnections();
    getContactPhoto();
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getAllPractice() {
    axios
      .get("http://localhost/ekconsul_new_ek/practice/")
      .then(function (response) {
        setSectorType(response.data);
      });
  }
  function getConnections() {
    // Send a query parameter with the request
    axios
      .get("http://localhost/ekconsul_new_ek/explore.php", {
        params: {
          area: practiceTitle,
        },
      })
      .then(function (response) {
        // Handle the response data
        setExplore(response.data);
      });
  }
  function getContactPhoto() {
    axios
      .get(
        `http://localhost/ekconsul_new_ek/team/teamSearch.php?name=${uniqueContact}`
      )
      .then(function (response) {
        setContactPhoto(response.data.map((item) => item.photo));
      });

    /*  const photoFilter = (name) => {
      const filteredData = TeamMembers.filter((item) => item.Name == name);
      const photoField = filteredData.map((item) => item.photo);
      setContactPhoto(photoField);
    };
    photoFilter(uniqueContact); */
  }

  // Use state to manage the toggle state, set an initial value (true for "on" or false for "off")

  function getPractice() {
    axios
      .get("http://localhost/ekconsul_new_ek/practiceSearch.php")
      .then(function (response) {
        setFilteredData(response.data);
      });
  }

  const changePracticeArea = (event) => {
    getContactPhoto();

    setPracticeTitle(event.target.name);

    //searchTeam(uniqueContact);
    getAllPractice();

    filterData(event.target.name);

    getConnections();
  };
  const [clickIndex, setClickIndex] = useState([]);
  let uniqueContact = [
    ...new Set(filteredData.map((project) => project.contact)),
  ];
  const projectCategory = (event) => {
    filterData(event.target.name);
  };

  return (
    <div className="container-fluid" id="practiceAllDiv">
      <div className="container-fluid" id="practiceContentDiv">
        {" "}
        <h4 style={{ marginTop: "10px" }}>PRACTICE AREAS</h4>
        {isSmallScreen ? (
          <div className="container-fluid">
            <Accordion>
              <Accordion.Item id="projectAccordion" eventKey="0">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={projectCategory}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="health"
                    id="health"
                  >
                    HEALTH
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container-fluid"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {filteredData.map((project, idx) => {
                      return (
                        <>
                          <a href="/team" id="fieldMemberLink">
                            {" "}
                            <Image
                              fluid
                              style={{
                                borderRadius: "50%",
                              }}
                              id="pAreaContact"
                              roundedCircle
                            />{" "}
                            <h4
                              style={{
                                padding: "10px",
                                fontSize: "20px",
                              }}
                            >
                              {project.contact}
                            </h4>{" "}
                          </a>{" "}
                          <div className="container-fluid">
                            {" "}
                            <Card id="projectCards">
                              <Card.Body id="practiceCardBody">
                                {" "}
                                <h3 id="projectTitle">{project.title}</h3>{" "}
                                <pre>
                                  <p
                                    id="projectBody"
                                    style={{
                                      textAlign: "left",
                                      letterSpacing: "2px",
                                      wordSpacing: "2px",
                                    }}
                                  >
                                    {project.body}
                                  </p>{" "}
                                </pre>
                              </Card.Body>{" "}
                            </Card>
                          </div>{" "}
                        </>
                      );
                    })}
                    <div className="container" id="ExploreDiv">
                      <h4 style={{ marginTop: "5vw" }}>EXPLORE</h4>
                      <Carousel indicators={false} id="projectCarousel">
                        {" "}
                        <Carousel.Item>
                          {" "}
                          <div className="row" id="practiceExploreDiv">
                            {" "}
                            {explore.slice(0, 2).map((connection, idx) => (
                              <Col>
                                {" "}
                                <a href="/e&k-connect" id="plxLink">
                                  {" "}
                                  <Card id="exploreCard">
                                    {" "}
                                    <Card.Body>
                                      {" "}
                                      <h3>{connection.title}</h3>{" "}
                                    </Card.Body>{" "}
                                  </Card>{" "}
                                </a>{" "}
                              </Col>
                            ))}
                          </div>{" "}
                        </Carousel.Item>
                      </Carousel>{" "}
                    </div>{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item id="projectAccordion" eventKey="1">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={projectCategory}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="energy"
                    id="energy"
                  >
                    ENERGY
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "Capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {filteredData.map((project, idx) => {
                      return (
                        <>
                          <Col md={2} style={{ margin: "4vw auto" }}>
                            {" "}
                            <a href="/team" id="fieldMemberLink">
                              {" "}
                              <Image
                                fluid
                                style={{
                                  borderRadius: "50%",
                                }}
                                id="pAreaContact"
                                roundedCircle
                              />{" "}
                              <h4
                                style={{
                                  padding: "10px",
                                  fontSize: "20px",
                                }}
                              >
                                {project.contact}
                              </h4>{" "}
                            </a>{" "}
                          </Col>
                          <Col md={9} id="practiceCol">
                            <div className="container">
                              {" "}
                              <Card id="projectCards">
                                <Card.Body id="practiceCardBody">
                                  {" "}
                                  <h3 id="projectTitle">
                                    {project.title}
                                  </h3>{" "}
                                  <pre>
                                    <p
                                      id="projectBody"
                                      style={{
                                        textAlign: "left",
                                        letterSpacing: "2px",
                                        wordSpacing: "2px",
                                      }}
                                    >
                                      {project.body}
                                    </p>{" "}
                                  </pre>
                                </Card.Body>{" "}
                              </Card>
                            </div>{" "}
                          </Col>
                        </>
                      );
                    })}{" "}
                    <div className="container" id="ExploreDiv">
                      <h4 style={{ marginTop: "5vw" }}>EXPLORE</h4>
                      <Carousel indicators={false} id="projectCarousel">
                        {" "}
                        <Carousel.Item>
                          {explore.slice(0, 2).map((connection, idx) => (
                            <Col>
                              {" "}
                              <a href="/e&k-connect" id="plxLink">
                                {" "}
                                <Card id="exploreCard">
                                  {" "}
                                  <Card.Body>
                                    {" "}
                                    <h3>{connection.title}</h3>{" "}
                                  </Card.Body>{" "}
                                </Card>{" "}
                              </a>{" "}
                            </Col>
                          ))}
                        </Carousel.Item>
                      </Carousel>{" "}
                    </div>{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item id="projectAccordion" eventKey="2">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={projectCategory}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="finance"
                    id="finance"
                  >
                    FINANCE
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "Capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {filteredData.map((project, idx) => {
                      return (
                        <>
                          <Col md={2} style={{ margin: "4vw auto" }}>
                            {" "}
                            <a href="/team" id="fieldMemberLink">
                              {" "}
                              <Image
                                fluid
                                style={{
                                  borderRadius: "50%",
                                }}
                                id="pAreaContact"
                                roundedCircle
                              />{" "}
                              <h4
                                style={{
                                  padding: "10px",
                                  fontSize: "20px",
                                }}
                              >
                                {project.contact}
                              </h4>{" "}
                            </a>{" "}
                          </Col>
                          <Col md={9} id="practiceCol">
                            <div className="container">
                              {" "}
                              <Card id="projectCards">
                                <Card.Body id="practiceCardBody">
                                  {" "}
                                  <h3 id="projectTitle">
                                    {project.title}
                                  </h3>{" "}
                                  <pre>
                                    <p
                                      id="projectBody"
                                      style={{
                                        textAlign: "left",
                                        letterSpacing: "2px",
                                        wordSpacing: "2px",
                                      }}
                                    >
                                      {project.body}
                                    </p>{" "}
                                  </pre>
                                </Card.Body>{" "}
                              </Card>
                            </div>{" "}
                          </Col>
                        </>
                      );
                    })}{" "}
                    <div className="container" id="ExploreDiv">
                      <h4 style={{ marginTop: "5vw" }}>EXPLORE</h4>
                      <Carousel indicators={false} id="projectCarousel">
                        {" "}
                        <Carousel.Item>
                          {explore.slice(0, 2).map((connection, idx) => (
                            <Col>
                              {" "}
                              <a href="/e&k-connect" id="plxLink">
                                {" "}
                                <Card id="exploreCard">
                                  {" "}
                                  <Card.Body>
                                    {" "}
                                    <h3>{connection.title}</h3>{" "}
                                  </Card.Body>{" "}
                                </Card>{" "}
                              </a>{" "}
                            </Col>
                          ))}
                        </Carousel.Item>
                      </Carousel>{" "}
                    </div>{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item id="projectAccordion" eventKey="3">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={projectCategory}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="innovation"
                    id="innovation"
                  >
                    INNOVATION
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "Capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      fluid
                      src={contactPhoto}
                      style={{
                        borderRadius: "50%",
                      }}
                      id="pAreaContact"
                      roundedCircle
                    />
                    <h4
                      style={{
                        padding: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {uniqueContact}
                    </h4>{" "}
                    {filteredData.map((project, idx) => {
                      return (
                        <>
                          <div className="container">
                            {" "}
                            <Card id="projectCards">
                              <Card.Body id="practiceCardBody">
                                {" "}
                                <h3 id="projectTitle">{project.title}</h3>{" "}
                                <pre>
                                  <p
                                    id="projectBody"
                                    style={{
                                      textAlign: "left",
                                      letterSpacing: "2px",
                                      wordSpacing: "2px",
                                    }}
                                  >
                                    {project.body}
                                  </p>{" "}
                                </pre>
                              </Card.Body>{" "}
                            </Card>
                          </div>{" "}
                        </>
                      );
                    })}
                    <div className="container" id="ExploreDiv">
                      <h4 style={{ marginTop: "5vw" }}>EXPLORE</h4>
                      <Carousel indicators={false} id="projectCarousel">
                        {" "}
                        <Carousel.Item>
                          {explore.slice(0, 2).map((connection, idx) => (
                            <Col>
                              {" "}
                              <a href="/e&k-connect" id="plxLink">
                                {" "}
                                <Card id="exploreCard">
                                  {" "}
                                  <Card.Body>
                                    {" "}
                                    <h3>{connection.title}</h3>{" "}
                                  </Card.Body>{" "}
                                </Card>{" "}
                              </a>{" "}
                            </Col>
                          ))}
                        </Carousel.Item>
                      </Carousel>{" "}
                    </div>{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : (
          <div className="row" id="practiceWhatRow">
            <div className="container-fluid" id="practiceAreaBtnGrp">
              {areas.map((area, idx) => (
                <div className="btn-group" id="buttonDiv">
                  <button
                    name={area.name}
                    type="button"
                    className="btn btn-primary"
                    data-toggle="button"
                    aria-pressed="true"
                    key={idx}
                    onChange={changePracticeArea}
                    onClick={changePracticeArea}
                    value={area.value}
                    id="practiceBtn"
                  >
                    {" "}
                    {area.name}
                  </button>
                </div>
              ))}
            </div>{" "}
            <div className="row">
              <Col
                className="column hidden-on-small"
                id="contactColPractice"
                md={2}
                style={{ marginTop: "10px" }}
              >
                <h5 style={{ justifyContent: "center", color: "#1AA99F" }}>
                  Contact
                </h5>
              </Col>
              <Col md={9} style={{ marginTop: "10px" }}>
                <h5 style={{ justifyContent: "center", color: "#1AA99F" }}>
                  Previous Project and Engagements{" "}
                </h5>
              </Col>
            </div>
            <Col md={2} style={{ margin: "4vw auto", padding: "0" }}>
              <div className="comntainer-fluid" id="contactPractice">
                <Card
                  onClick={() => {
                    searchTeam(uniqueContact);
                    getContactPhoto();
                  }}
                  id="contactCardPractice"
                >
                  {" "}
                  <Card.Body>
                    <Image
                      fluid
                      src={contactPhoto}
                      style={{
                        borderRadius: "50%",
                      }}
                      id="pAreaContact"
                      roundedCircle
                    />
                    <h4
                      style={{
                        padding: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {uniqueContact}
                    </h4>{" "}
                  </Card.Body>{" "}
                </Card>
              </div>
            </Col>
            <Col md={9} id="practiceCol">
              <div className="container">
                {filteredData.map((project, idx) => {
                  return (
                    <Card id="projectCards">
                      <Card.Body id="practiceCardBody">
                        {" "}
                        <h3 id="projectTitle">{project.title}</h3>{" "}
                        <pre>
                          <p
                            id="projectBody"
                            style={{
                              textAlign: "left",
                              letterSpacing: "2px",
                              wordSpacing: "2px",
                            }}
                          >
                            {project.body}
                          </p>{" "}
                        </pre>
                      </Card.Body>{" "}
                    </Card>
                  );
                })}{" "}
              </div>{" "}
            </Col>
            <div className="container" id="ExploreDiv">
              <h4 style={{ marginTop: "5vw" }}>EXPLORE</h4>
              <Carousel indicators={false} id="projectCarousel">
                {" "}
                <Carousel.Item>
                  {" "}
                  <div className="row" id="practiceExploreDiv">
                    {explore.slice(0, 3).map((connection, idx) => (
                      <Col key={idx}>
                        {" "}
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to={`/articles/${connection.title}`}
                        >
                          <Card id="exploreCard">
                            {" "}
                            <Card.Body>
                              {" "}
                              <h3>{connection.title}</h3>{" "}
                            </Card.Body>{" "}
                          </Card>{" "}
                        </Link>{" "}
                      </Col>
                    ))}
                  </div>{" "}
                </Carousel.Item>
              </Carousel>{" "}
            </div>
          </div>
        )}
      </div>{" "}
      <Modal
        show={show}
        responsive
        size="xl"
        id="teamModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        {" "}
        <Modal.Body>
          <div
            className="container-fluid"
            id="modalDiv"
            style={{ height: "auto" }}
          >
            {" "}
            {team.map((item) => {
              return (
                <div className="container-fluid" id="teamModal">
                  <div className="container" id="teamHeader">
                    <h4 key={item.name} id="memberName">
                      {item.name}
                    </h4>
                    <p key={item.name} id="memberTitle">
                      {item.title}
                      <br />
                      {item.email}
                    </p>
                    <div className="container" id="contactImageCard">
                      <Image
                        fluid
                        src={contactPhoto}
                        style={{
                          maxHeight: "250px",
                          maxWidth: "200px",
                          margin: "auto",
                          marginBottom: "30px",
                          borderRadius: "50%",
                        }}
                        id="pAreaContact"
                        roundedCircle
                      />
                      <Image
                        fluid
                        src={lnGr}
                        style={{ margin: "auto" }}
                        alt="https://icons8.com/icon/447/linkedin"
                      />
                    </div>
                  </div>

                  <div id="teamCarBody" sm={8}>
                    <pre>
                      {" "}
                      <p id="memberBody">{item.body}</p>
                    </pre>
                  </div>
                </div>
              );
            })}{" "}
          </div>
        </Modal.Body>{" "}
      </Modal>
    </div>
  );
}
